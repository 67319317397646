<template>
  <div>
    <FormsLoginForm @submit="login" />

    <div class="mt-4">
      <button @click.prevent="openRegister">
        Noch kein Konto? Registrieren
      </button>

      <p>
        <button @click.prevent="openForgetPassword">Passwort vergessen?</button>
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
defineEmits(['close'])

const { openModal } = useModal()

function openRegister() {
  openModal('Register')
}

function openForgetPassword() {
  openModal('ForgetPassword')
}

function login(emailOrUsername: string, password: string) {
  console.log('login called', emailOrUsername, password)
}
</script>
